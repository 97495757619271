import React from "react"
import { SlideType, SlideVariant } from "@components/Hero/Slide"
import Hero from "@components/Hero"
import { HomepageBannerBlock } from "@lib/kontent/models/HomepageBannerBlock"
import { getKontentImage } from "@lib/kontent"

const HomepageHero: React.FC<{ slides: HomepageBannerBlock[] }> = ({
  slides,
}) => {
  const items: SlideType[] = slides
    .map((slide) => {
      const imageSmall = slide.mobile.value[0]?.url
      const imageLarge = slide.tablet___desktop.value[0]?.url

      return {
        preloadImage: getKontentImage(imageSmall, { width: 50 }),
        images: [
          getKontentImage(imageSmall, { width: 750 }),
          getKontentImage(imageLarge, { width: 1200 }),
          getKontentImage(imageLarge, { width: 1400 }),
          getKontentImage(imageLarge, { width: 1920 }),
        ],
        intro: slide.intro.value,
        title: slide.title.value,
        variant:
          (slide.style.value[0]?.name as SlideVariant) ||
          ("light" as SlideVariant),
        link: {
          href: slide.link_url.value,
          text: slide.link_text.value,
        },
      }
    })
    .filter((i) => i !== null)

  return <Hero slides={items} />
}

export default HomepageHero
