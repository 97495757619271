import React, { useEffect, useRef, useState } from "react"
import Arrows from "./Arrows"
import * as S from "./elements"
import Navigation from "./Navigation"
import Slide, { SlideType } from "./Slide"

interface Props {
  slides: SlideType[]
}

const Slider: React.FC<Props> = ({ slides }) => {
  const [active, setActive] = useState(0)
  const interval = useRef<any>(null)
  const prev = () => setActive(active === 0 ? slides.length - 1 : active - 1)
  const next = () => setActive(active === slides.length - 1 ? 0 : active + 1)

  useEffect(() => {
    interval.current = setInterval(() => {
      next()
    }, 6000)
    return () => {
      clearInterval(interval.current)
    }
  }, [active]) // eslint-disable-line

  return (
    <S.Container>
      {slides.map((slide, index) => (
        <Slide {...slide} key={index} active={active === index} />
      ))}
      <Navigation active={active} />
      <Arrows nextSlide={next} prevSlide={prev} />
    </S.Container>
  )
}

export default Slider
