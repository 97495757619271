import React from "react"
import * as S from "./elements"
import { ChevronRight, ChevronLeft } from "@components/Icons"

interface Props {
  prevSlide: () => void
  nextSlide: () => void
}

const Arrows: React.FC<Props> = ({ prevSlide, nextSlide }) => {
  return (
    <>
      <S.LeftButton onClick={prevSlide}>
        <ChevronLeft />
      </S.LeftButton>
      <S.RightButton onClick={nextSlide}>
        <ChevronRight />
      </S.RightButton>
    </>
  )
}

export default Arrows
