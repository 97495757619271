import { breakpoints } from "@config/theme"
import { rem } from "polished"
import styled from "styled-components"

export const Navigation = styled.div`
  position: absolute;
  bottom: 1rem;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 6;
  @media screen and (min-width: ${breakpoints.small}) {
    bottom: 2rem;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    width: ${rem(1000)};
  }
`

export const NavigationItem = styled.div<{ active: boolean }>`
  transition: opacity 0.25s ease-in-out;
  cursor: none;
  border-radius: 0;
  border: none;
  height: 2px;
  background: white;
  opacity: 0.3;
  margin: 0;
  width: calc(100% / 3 - 1rem);
`

export const Indicator = styled.div<{ active: number }>`
  transition: transform 0.5s ease-in-out;
  position: absolute;
  left: 0;
  background: #fff;
  height: 2px;
  bottom: 0;
  z-index: 2;
  width: calc(100% / 3 - 1rem);
  transform: ${(p) =>
    p.active === 1
      ? `translateX(calc(100% + 1.5rem))`
      : p.active === 2
      ? `translateX(calc(200% + 3rem))`
      : "translateX(0)"};
`
