import React from "react"
import * as S from "./Navigation.elements"
interface NavigationProps {
  active: number
}

const Navigation: React.FC<NavigationProps> = ({ active }) => {
  return (
    <S.Navigation>
      <S.NavigationItem active={active === 0}></S.NavigationItem>
      <S.NavigationItem active={active === 1}></S.NavigationItem>
      <S.NavigationItem active={active === 2}></S.NavigationItem>
      <S.Indicator active={active} />
    </S.Navigation>
  )
}

export default Navigation
