import Footer from "@components/Footer"
import { Box } from "@components/Layout"
import SEO from "@components/SEO"
import { WomenVoteHomepageBanner } from "@components/_women-vote/HomepageBanner"
import ContentBlocks from "@components/content-blocks"
import { getDataCodenameValues } from "@lib/get-data-codename-values"
import { getKontentImage } from "@lib/kontent"
import { SingleItemResponse } from "@lib/kontent/fetchContent"
import { get2023Homepage } from "@lib/kontent/get2023Homepage"
import { getWomenVoteContent } from "@lib/kontent/getWomenVoteContent"
import { Homepage2023 as Homepage2023Model } from "@lib/kontent/models/Homepage2023"
import { HomepageBannerBlock } from "@lib/kontent/models/HomepageBannerBlock"
import { LandingpageVerkiezingOndernemendeVrouw } from "@lib/kontent/models/LandingpageVerkiezingOndernemendeVrouw"
import HomepageHero from "@screens/home-v2/Hero"
import { GetStaticPaths, GetStaticProps } from "next"
import { FC } from "react"

type Homepage2023Props = SingleItemResponse<Homepage2023Model> & {
  womenVoteContent: SingleItemResponse<LandingpageVerkiezingOndernemendeVrouw>
}

const Homepage2023: FC<Homepage2023Props> = ({
  item,
  modular_content,
  womenVoteContent,
}) => {
  const bannerKeys = item.elements.banner.value || []
  const contentBlockKeys = getDataCodenameValues(
    item.elements.content_blocks__blocks.value
  )
  const contentBlocks = contentBlockKeys.map((key) => ({
    type: modular_content[key].system.type,
    elements: modular_content[key].elements,
  }))
  const bannerItems = bannerKeys.map((key) => modular_content[key].elements)
  const displayVoteBanner =
    womenVoteContent.item.elements.homepage_banner_display_homepage_banner
      .value[0].codename === "yes"

  return (
    <>
      <SEO
        title={item.elements.seo__meta_title.value}
        description={item.elements.seo__meta_description.value}
        imageUrl={getKontentImage(item.elements.seo__meta_image.value[0]?.url, {
          width: 600,
        })}
      />
      {displayVoteBanner && (
        <WomenVoteHomepageBanner
          title={womenVoteContent.item.elements.homepage_banner_title.value}
          description={
            womenVoteContent.item.elements.homepage_banner_description.value
          }
        />
      )}
      {bannerItems && bannerItems.length > 0 && (
        <HomepageHero slides={bannerItems as HomepageBannerBlock[]} />
      )}
      <Box width="100%" bg="white" pb="3rem">
        <ContentBlocks
          contentBlocks={contentBlocks}
          modularContent={modular_content}
        />
      </Box>
      <Footer />
    </>
  )
}

export default Homepage2023

export const getStaticProps: GetStaticProps = async ({ params, preview }) => {
  const lang = (params.lang as string) || "nl"

  const content = await get2023Homepage({
    language: lang,
    preview,
  })
  const womenVoteContent = await getWomenVoteContent({
    language: lang,
    preview,
  })

  return {
    props: {
      ...content,
      womenVoteContent,
    },
  }
}

export const getStaticPaths: GetStaticPaths = async () => {
  const paths = ["nl", "en"].map((lang) => ({
    params: { lang },
  }))

  return { paths, fallback: false }
}
