import { ButtonAnchor } from "@components/Buttons"
import { Box } from "@components/Layout"
import LazyImage from "@components/LazyImage"
import { useRouter } from "next/router"
import React, { useEffect, useRef } from "react"
import * as S from "./Slide.elements"
export type SlideVariant = "dark" | "light"

export type SlideType = {
  preloadImage: string
  images: string[]
  intro: string
  title: string
  link: {
    text: string
    href: string
  }
  variant: SlideVariant
}

export interface SlideProps extends SlideType {
  active: boolean
}

const Slide: React.FC<SlideProps> = ({
  intro,
  title,
  link,
  preloadImage,
  images,
  active,
  variant,
}) => {
  const router = useRouter()
  const hasBeenActive = useRef(false)

  useEffect(() => {
    if (active && !hasBeenActive.current) {
      hasBeenActive.current = true
    }
  }, [active])

  return (
    <S.Slide active={active} variant={variant}>
      <LazyImage
        placeholderSrc={preloadImage}
        loading={active || hasBeenActive.current ? "lazy" : "none"}
        sources={images}
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
      />
      <S.SlideContainer>
        <S.Content>
          <Box>
            <S.Intro as="h4">{intro}</S.Intro>
            <S.Title>{title}</S.Title>
          </Box>
          <S.ButtonContainer>
            <ButtonAnchor
              maxWidth={310}
              icon="arrow-right"
              variant={variant === "light" ? "white-border" : "dark-border"}
              onClick={() => router.push(link.href)}
            >
              {link.text}
            </ButtonAnchor>
          </S.ButtonContainer>
        </S.Content>
      </S.SlideContainer>
    </S.Slide>
  )
}

export default Slide
