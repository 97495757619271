import { getGraphQLClient } from "@lib/graphql-client"
import { useQuery } from "@tanstack/react-query"
import { FC, useEffect } from "react"
import { create } from "zustand"
import Cookies from "js-cookie"

export const CART_REFERENCE_KEY = "choices_cart_reference"

type CartReferenceStore = {
  reference: string | null
  hasInitiatedReferenceFromStorage: boolean
  setReference: (reference: string) => void
}
export const useCartReferenceStore = create<CartReferenceStore>((set) => ({
  reference: null,
  hasInitiatedReferenceFromStorage: false,
  setReference: (reference: string) =>
    set({ reference, hasInitiatedReferenceFromStorage: true }),
}))

const { sdk } = getGraphQLClient()

export const LoadCart: FC = () => {
  const cartReferenceStore = useCartReferenceStore()

  const { data } = useQuery(
    ["cart", cartReferenceStore.reference],
    () => sdk.Cart({ reference: cartReferenceStore.reference }),
    {
      enabled: cartReferenceStore.hasInitiatedReferenceFromStorage,
    }
  )

  // Initiate reference from cookies to activate useQuery
  // -- If there is no reference in cookies, API will return a new cart with a new reference
  // -- Else, API will return the cart with the reference from cookies
  useEffect(() => {
    cartReferenceStore.setReference(Cookies.get(CART_REFERENCE_KEY) || null)
  }, []) // eslint-disable-line

  // Store the reference from the cart query in local storage so that it can be used on the next page load
  useEffect(() => {
    if (data && data.choicesCart) {
      Cookies.set(CART_REFERENCE_KEY, data.choicesCart.id)
      cartReferenceStore.setReference(data.choicesCart.id)
    }
  }, [data]) // eslint-disable-line

  return null
}
