import { ButtonAnchor } from "@components/Buttons"
import { useIntl } from "@components/Intl"
import IntlLink from "@components/Intl/IntlLink"
import { ParagraphS, ParagraphTitle } from "@components/Typography"
import React, { FC } from "react"
import styled from "styled-components"

type WomenVoteHomepageBannerProps = {
  title: string
  description: string
}

const Parent = styled.div`
  width: 100%;
  background-color: #e7e0dc;
  padding: 1rem 1.25rem;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem;
  align-content: center;
`

const Button = styled(ButtonAnchor)`
  padding: 0.5rem;
  height: auto;
  width: auto;
  justify-self: flex-end;
`

export const WomenVoteHomepageBanner: FC<WomenVoteHomepageBannerProps> = ({
  title,
  description,
}) => {
  const { messages } = useIntl()

  return (
    <Parent>
      <div>
        <ParagraphTitle mb="0">{title}</ParagraphTitle>
        <ParagraphS color="purple">{description}</ParagraphS>
      </div>
      <IntlLink
        href={messages.navigation.womenVote2023.url}
        as={messages.navigation.womenVote2023.url}
      >
        <Button variant="purple-border">{messages.vote.voteNow}</Button>
      </IntlLink>
    </Parent>
  )
}
