import styled, { css } from "styled-components"
import { breakpoints } from "@config/theme"
import { rem } from "polished"
import { SlideVariant } from "./Slide"
import { TitleXXLStyle } from "@components/Typography/TextStyles/TitleXXL"
import { LabelStyle } from "@components/Typography/TextStyles/Label"

export const Content = styled.div`
  position: absolute;
  max-width: ${rem(680)};
  padding-left: 2rem;

  @media screen and (max-width: ${breakpoints.small}) {
    height: 60%;
    width: 100%;
    bottom: 4rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    flex-direction: column;
    padding: 0 1rem;
  }
  @media screen and (min-width: ${breakpoints.small}) {
    transform: translateY(-50%);
    top: 50%;
  }
  @media screen and (min-width: ${breakpoints.xlarge}) {
    padding-left: 0;
  }

  /* This targets IE11, check out https://stackoverflow.com/questions/18907131/detecting-ie11-using-css-capability-feature-detection#answer-35063378 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    &:before {
      display: none;
    }
  }
`

export const Intro = styled.span`
  transition: opacity 0.4s ease-in-out;
  display: block;
  opacity: 0;
  transition-delay: 1.25s;
  ${LabelStyle};
  margin-bottom: 1.5rem;
  @media screen and (max-width: ${breakpoints.small}) {
    padding: 0 2rem;
  }
`
export const Title = styled.h2`
  ${TitleXXLStyle};
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  transition-delay: 0.75s;
  margin-bottom: 1.5rem;
  @media screen and (min-width: ${breakpoints.small}) and (max-width: ${breakpoints.medium}) {
    max-width: 70%;
  }
`
export const ButtonContainer = styled.div`
  transition: opacity 0.25s ease-in-out;
  opacity: 0;
  transition-delay: 1s;
  @media screen and (max-width: ${breakpoints.small}) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`

export const Slide = styled.div<{ active: boolean; variant: SlideVariant }>`
  transition: opacity 0.5s ease-in-out;
  transition-delay: ${(p) => (p.active ? "0.25s" : "0")};
  opacity: ${(p) => (p.active ? 1 : 0)};
  pointer-events: ${(p) => (p.active ? "all" : "none")};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  ${Intro},
  ${Title} {
    color: ${(p) => (p.variant === "light" ? "#fff" : "#000")};
  }

  ${(p) =>
    p.active &&
    css`
      ${Intro},
      ${Title},
      ${ButtonContainer} {
        transform: translateY(0);
        opacity: 1;
      }
    `}
`

export const SlideContainer = styled.div`
  width: 95%;
  max-width: ${rem(1400)};
  position: absolute;
  left: 50%;
  height: 100%;
  top: 0;
  transform: translateX(-50%);
  z-index: 6;
  &::before {
    position: absolute;
    top: 0;
    left: -10%;
    content: "";
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 10%,
      rgba(0, 0, 0, 0.2) 90%,
      rgba(0, 0, 0, 0) 100%
    );
    height: 100%;
    width: 120%;
    z-index: -1;
  }
`
