import Header from "@components/Page/Header"
import React from "react"
import * as S from "./elements"
import { SlideType } from "./Slide"
import Slider from "./Slider"

interface Props {
  slides: SlideType[]
}
const Hero: React.FC<Props> = ({ slides }) => {
  return (
    <S.Wrap>
      <Header mode="transparant"></Header>
      <Slider slides={slides} />
    </S.Wrap>
  )
}

export default Hero
