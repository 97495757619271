import { breakpoints } from "@config/theme"
import styled, { css } from "styled-components"

export const Container = styled.section`
  width: 100%;
  position: relative;
  background: #eee;
  overflow: hidden;
  &::before {
    padding-top: 70vh;
    content: "";
    display: block;
    @media screen and (min-width: ${breakpoints.small}) {
      padding-top: 60%;
    }
    @media screen and (min-width: ${breakpoints.medium}) {
      padding-top: 50%;
    }
    @media screen and (min-width: ${breakpoints.large}) {
      padding-top: 45%;
    }
  }
`

export const Wrap = styled.div`
  position: relative;
  width: 100%;
`

const ArrowBase = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background: transparent;
  border: none;
  outline: none;
  opacity: 0.5;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  padding: 1rem;
  z-index: 7;

  svg {
    fill: white;
    height: 2rem;
    width: 2rem;
  }

  &:hover {
    opacity: 1;
  }
`

export const RightButton = styled.button`
  ${ArrowBase};
  left: initial;
  right: 0;
`

export const LeftButton = styled.button`
  ${ArrowBase};
`
